body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.result-table{
  border-collapse: collapse;
  /* max-width: 800px; */
}

.result-table tr>td:first-child{
  text-align: left;  
}

.result-table tr>td{
  text-align: center;
}

.result-table, .result-table td{
  border: 1px solid silver;
}

.result-table td{
  padding: 5px;
}

.calculated-value{
  background-color:black;
  color: white;
}

.corected-row{
  background-color:lightgrey;
}

.header-row{
  background-color: dimgray;
  color: white;
}

.footer-row{
  background-color: dimgray;
  color: white;
}

.input-value{
  font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.input-value input{
  font-size: 20px;
    font-weight: bold;
    padding: 10px;
}


.result-table td{
  width: 50px;
}
.result-table td:nth-child(1){
  width: 300px;
}
.result-table td:nth-child(2){
  width: 100px;
  padding: 0;
}
.result-table td:nth-child(2) input{
  padding: 2px;
  width: 50px;
}

.result-table tr:hover{
  background-color: gold;
}
.the-navigation ul{
  display: inline-block;
  list-style: none;
  padding: 0;
}
.the-navigation li{
  display: inline-block;
  min-width: 5em;
  margin: 0 0.5em;  
}

.the-navigation a {
  text-decoration: none;
  display: block;
  font-family:Arial;
  position: relative;
  color: black;
  background-color: silver;
  padding: 10px;
  border-radius: 5px;
}

.the-navigation a.active{
  background-color: gold;
} 



@media print {
  .the-navigation{
    display: none;
  }

  body{
    padding: 0;
    margin: 0;

    font-size: 10px !important;
  }

  table td{
    padding: 2px !important;
  }

  

}